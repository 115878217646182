import PageRoutes from './pages'
import { isUserLoggedIn } from '@utils'
import ClientesRoutes from './clientes'
import EmpresasRoutes from './empresas'
import CobrosRoutes from './cobros'
import TransferenciasRoutes from './transferencias'
import ValidacionRoutes from './validacion'


// ** Document title
const TemplateTitle = 'Cobromix'

// ** Default Route
const handleLogin = () => {
    if (isUserLoggedIn() !== null) {

        const userData = JSON.parse(localStorage.getItem('userData'))
        const mostrarFuncionalidadCompleta = userData?.usuario?.mostrar_funcionalidad_completa

        if (mostrarFuncionalidadCompleta !== 1) {
            return '/numeros'
        }
        
        return '/inicio'
    } else {
        return '/iniciar-sesion'
    }
}

const DefaultRoute = handleLogin()

// ** Merge Routes
const Routes = [
    ...PageRoutes,
    ...ClientesRoutes,
    ...EmpresasRoutes,
    ...CobrosRoutes,
    ...TransferenciasRoutes,
    ...ValidacionRoutes
]

export { DefaultRoute, TemplateTitle, Routes }
