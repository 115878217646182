import { Home, Briefcase, Users, DollarSign, Activity, UserCheck } from 'react-feather'

export default [
  {
    id: 'inicio',
    title: 'Inicio',
    icon: <Home size={20} />,
    navLink: '/inicio'
  },
  {
    id: 'clientes',
    title: 'Clientes',
    icon: <Users size={20} />,
    navLink: '/clientes'
  },
  {
    id: 'validacion',
    title: 'Validación',
    icon: <UserCheck size={20} />,
    navLink: '/validacion'
  },
  {
    id: 'empresas',
    title: 'Empresas',
    icon: <Briefcase size={20} />,
    navLink: '/empresas'
  },
  {
    id: 'cobros',
    title: 'Cobros',
    icon: <DollarSign size={20} />,
    navLink: '/cobros'
  },
  {
    id: 'transacciones',
    title: 'Transferencias',
    icon: <Activity size={20} />,
    navLink: '/transferencias'
  }
]
